import { Button } from '@mantine/core';
import { GetOnboardingRequestDetailResponse } from '@shared/api/models';
import {
    getGetRequestsQueryKey,
    useCreateRequestForExistingCustomer,
    useCreateRequestForNewCustomer
} from '@shared/api/queries/requests/requests';
import { CustomerSelection, CustomerType } from '@shared/components/customer/customerSelection';
import { TrackingEvents } from '@shared/utils/trackingsEvents';
import { useQueryClient } from '@tanstack/react-query';
import {
    LoadingAnimation,
    Notifications,
    sendTrackingEvent,
    SplitLayout,
    toastNotifications,
    UserContextMenuItem
} from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetInboxToken } from 'notificationsApi/queries/inbox/inbox';
import { knockConfiguration } from 'shared/utils';
import background from './assets/welcome-bg.jpg';

export const SelectCustomerView = () => {
    const { t } = useTranslation();
    const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.Existing);
    const [customerNumber, setCustomerNumber] = useState<string>('');
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { data: inboxToken } = useGetInboxToken();

    const { mutateAsync: createRequestForExistingCustomerAsync, isPending: isCreateRequestForExistingLoading } =
        useCreateRequestForExistingCustomer();
    const { mutateAsync: createRequestForNewCustomerAsync, isPending: isCreateRequestForNewCustomerLoading } =
        useCreateRequestForNewCustomer();

    const handleContinue = async () => {
        sendTrackingEvent(TrackingEvents.Started);

        try {
            let createdRequest: GetOnboardingRequestDetailResponse | null;
            if (customerNumber) {
                createdRequest = await createRequestForExistingCustomerAsync({
                    data: {
                        customerIdentifier: customerNumber
                    }
                });
            } else {
                createdRequest = await createRequestForNewCustomerAsync({
                    data: { companyData: { useElectronicInvoice: false } }
                });
            }

            queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });

            if (createdRequest && createdRequest.data) {
                navigate(`/request/${createdRequest.data.id}`);
            }
            toastNotifications.sucess({ title: t('success'), message: t('dataSaved', { data: t('request') }) });
        } catch {
            toastNotifications.error({ title: t('failed'), message: t('dataNotSaved', { data: t('request') }) });
        }
    };

    const handleCustomerTypeChanged = (value: CustomerType) => {
        setCustomerType(value);
        if (value === CustomerType.New) {
            sendTrackingEvent(TrackingEvents.NewCustomer);
        }
    };

    if (isCreateRequestForExistingLoading || isCreateRequestForNewCustomerLoading) {
        return <LoadingAnimation text={t('creatingRequest')} />;
    }

    return (
        <SplitLayout
            backgroundImage={background}
            childContainerClassName="h-full justify-center items-center xs:max-w-[464px]"
            className="m-auto"
            versionNumber={APP_VERSION}
        >
            <UserContextMenuItem
                icon="domain"
                title={t('myRegistrations')}
                onClick={() => navigate('/manageRequests')}
            />
            {inboxToken?.data && (
                <Notifications
                    userId={inboxToken.data.userIdentifier}
                    userToken={inboxToken.data.token}
                    {...knockConfiguration}
                />
            )}
            <div className="flex flex-col gap-6">
                <h1 className="text-5xl mb-2">{t('startRegisterYourCompany')}</h1>
                <h4 className="text-2xl">{t('areExistingCustomer')}</h4>

                <CustomerSelection
                    customerNumber={customerNumber}
                    customerType={customerType}
                    setCustomerNumber={setCustomerNumber}
                    onCustomerTypeChanged={handleCustomerTypeChanged}
                />
                <Button
                    className="mt-2 w-full"
                    classNames={{ inner: 'm-auto' }}
                    disabled={customerType === CustomerType.Existing && !customerNumber}
                    onClick={handleContinue}
                >
                    {t('continue')}
                </Button>
            </div>
        </SplitLayout>
    );
};
